import React from 'react';
import './style/Timeline.css';
import { Container, Row, Col } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

export const Exp = () => {
    const data = [
        {
            title: 'Trainee Software Engineer',
            des: 'CodeGen International (Pvt) Ltd',
            startDate: '2023-08-01', endDate: '2024-02-01'
        },
        {
            title: 'Software Engineer',
            des: 'CodeGen International (Pvt) Ltd',
            startDate: '2024-02-01', endDate: 'Present'
        }
    ];

    const calculateDuration = (index, startDate, endDate = new Date()) => {
        const start = new Date(startDate);
        const end = endDate === 'Present' ? new Date() : new Date(endDate);

        const totalMonths = (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth();
        const years = Math.floor(totalMonths / 12);
        const months = totalMonths % 12;

        const yearString = years > 1 ? `${years} yrs` : years === 1 ? '1 yr' : '';
        const monthString = months > 1 ? `${months} mons` : months === 1 ? '1 mo' : '';

        const startToLocale = new Date(startDate).toLocaleString('default', { month: 'short', year: 'numeric' });
        const endToLocale = endDate === 'Present' ? 'Present' : new Date(endDate).toLocaleString('default', { month: 'short', year: 'numeric' });
        return `${startToLocale} - ${endToLocale} | ${yearString}${yearString && monthString ? ' ' : ''}${monthString}`;
    };

    return (
        <section className="experience" id="experience">
            <Container fluid={true} className="px-4">
                <Row>
                    <Col size={12}>
                        <h2>Experience</h2>
                        <br />
                    </Col>
                </Row>
                <br />
                <div className="timeline">
                    <div className="center-line"></div>
                    {data.reverse().map((item, index) => (
                        <AnimationOnScroll key={`animation-${index}`} animateIn="animate__slideInUp" animateOnce delay={index % 2 === 0 ? 100 : 150}>
                            <div key={index} className={`row ${index % 2 === 0 ? 'row-1' : 'row-2'}`}>
                                <section>
                                    <div className="main">
                                        <h3>{item.title}</h3>
                                    </div>
                                    <div className="content">
                                        <p>{item.des}</p>
                                        <span className="duration">{calculateDuration(index, item.startDate, item.endDate)}</span>
                                    </div>
                                </section>
                            </div>
                        </AnimationOnScroll>
                    ))}
                </div>
            </Container>
        </section>
    );
};