import { useState, useEffect } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";

import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/linkedin.png";
import navIcon2 from "../assets/img/github.png";
import navIcon3 from "../assets/img/facebook.png";
import navIcon4 from "../assets/img/gmail.png";
import dark from "../assets/img/dark.png";
import light from "../assets/img/light.png";

export const NavBar = (props) => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);
  const [themeIcon, setThemeIcon] = useState(dark);

  const linkedIn = "https://linkedin.com/in/perera-naveen/";
  const github = "https://github.com/nveen9";
  const facebook = "https://www.facebook.com/naveen.perera99";
  const mail = "naveenperera9@gmail.com";
  const cvURL =
    "https://drive.google.com/file/d/1OZ68SzkqXQufF2qsyojU_Csga_Y2ti3j/view";

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  const { changeTheme } = props;

  const themeChange = () => {
    if (themeIcon === light) {
      setThemeIcon(dark);
    } else {
      setThemeIcon(light);
    }
    changeTheme();
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container fluid={true} className="px-4">
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#home"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("home")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="#experience"
                className={
                  activeLink === "experience" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("experience")}
              >
                Experience
              </Nav.Link>
              <Nav.Link
                href="#skills"
                className={
                  activeLink === "skills" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("skills")}
              >
                Skills
              </Nav.Link>
              <Nav.Link
                href="#projects"
                className={
                  activeLink === "projects"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("projects")}
              >
                Projects
              </Nav.Link>
            </Nav>
            <Nav className="ms-auto"></Nav>
            <Nav className="ms-auto"></Nav>
            <Nav className="ms-auto"></Nav>
            <Nav className="ms-auto"></Nav>
            <Nav className="ms-auto"></Nav>
            <Nav className="ms-auto"></Nav>
            <Nav className="ms-auto"></Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a
                  href={linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={navIcon1} alt="linkedIn" />
                </a>
                <a
                  href={github}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={navIcon2} alt="github" />
                </a>
                <a
                  href={facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={navIcon3} alt="facebook" />
                </a>
                <a href={`mailto: ${mail}`}>
                  <img src={navIcon4} alt="gmail" />
                </a>
              </div>
            </span>
            <span className="navbar-text">
              <a className="cv-text" href={cvURL} target="_blank" rel="noopener noreferrer">
                <h6>Resume</h6>
              </a>
            </span>
            <span className="navbar-text">
              <label className="switch">
                <input type="checkbox" onChange={() => themeChange()} />
                <span className="slider"></span>
              </label>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};
