import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import proPic from '../assets/img/propic.png';

export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(200 - Math.random() * 100);
    const period = 500;

    useEffect(() => {
        const phrasesToRotate = ["Aspiring", "Researching", "SoftwareEngineer"];
        
        const tick = () => {
            let i = loopNum % phrasesToRotate.length;
            let fullText = phrasesToRotate[i];
            let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

            setText(updatedText);

            if (isDeleting) {
                setDelta(prevDelta => prevDelta / 10);
            }

            if (!isDeleting && updatedText === fullText) {
                setIsDeleting(true);
                setDelta(period);
            } else if (isDeleting && updatedText === '') {
                setIsDeleting(false);
                setLoopNum(loopNum + 1);
                setDelta(200);
            }
        };

        let ticker = setInterval(() => {
            tick();
        }, delta);

        return () => clearInterval(ticker);
    }, [text, delta, loopNum, isDeleting]);

    return (
        <section className="banner" id="home">
            <Container fluid className="px-6">
                <Row className="aligh-items-center">
                    <Col xs={12} md={6} xl={8}>
                        <span><h2>HELLO, I'M</h2></span>
                        <span className="tagline">NAVEEN PERERA</span>
                        <h1>{`#`}<span className="txt-rotate" data-period="500" data-rotate='["AspiringResearching", "Researching", "SoftwareEngineer"]'><span className="wrap">{text}</span></span></h1>
                        <p>An aspiring and researching Software Engineer with a passion for adapting to ever-evolving challenges and equipped with a diverse skill set from the experience for exploring innovative solutions and bringing new perspectives to the table.</p>
                    </Col>
                    <Col xs={12} md={6} xl={4}>
                        <img src={proPic} alt="profilepic" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}