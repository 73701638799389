import './App.css';
import { NavBar } from './components/NavBar';
import { Banner } from './components/Banner';
import { Exp } from './components/Exp';
import { Skills } from './components/Skills';
import { Projects } from './components/Projects';
import { Footer } from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';

import { createContext, useState } from 'react';

export const ThemeContext = createContext(null);

function App() {
  const [theme, setTheme] = useState("light");

  const changeTheme = () => {
    setTheme((current) => (current === "light" ? "dark" : "light"));
  };
  return (
    <ThemeContext.Provider value={{theme, changeTheme}}>
    <div className="App" id={theme}>
      <NavBar changeTheme={changeTheme}/>
      <Banner />
      <Exp />
      <Skills />
      <Projects />
      <Footer />
    </div>
    </ThemeContext.Provider>
  );
}

export default App;
