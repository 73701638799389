import { Container, Row, Col } from "react-bootstrap";
import logoF from "../assets/img/logo_footer.png";

export const Footer = () => {
  function getCurrentYear() {
    return new Date().getFullYear();
  }

  return (
    <footer className="footer">
      <Container fluid={true} className="px-4">
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logoF} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <p>Copyright © {getCurrentYear()} NaveenPerera. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}